import React, { useEffect, useMemo, useState } from 'react';
import faceService from '../../../services/operation/face.service';
import { Button, Input, Popconfirm, Space, Spin, Table, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const CollectionList = () => {
    const [state, setState] = useState({
        loading : false,
        collections : [],
    })

    const [filter, setFilter] = useState({
        search : null,
    })

    const [timer, setTimer] = useState();

    const [refresh, setRefresh] = useState(0);

    const collections = useMemo(() => {
        const { search, } = filter;
        let r_collections = [...state?.collections];
        if(search){
            r_collections = state?.collections?.filter(x => (x?.collection?.includes(search)));
        }
        return r_collections;
    }, [state?.collections, filter])

    const fetchCollections = async () => {
        setState(state => ({...state, loading : true}));
        const faceResponse = await faceService?.fetchCollections();
        const collections = faceResponse?.collections?.map((collection, index) => ({collection, index}));
        

        setState(state => ({...state, loading : false, collections : collections,}));
    }

    const deleteCollection = async (collection_id) => {
        const deleteCollectionResponse = await faceService?.deleteCollection(collection_id);

        //notification
        notification.success({
            message : collection_id,
            description : `Successfully deleted ${collection_id} from collection`,
        })

        setRefresh(refresh + 1);
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search : input, }));
        }, 300);
        setTimer(newTimer);
    }

    useEffect(() => {
        fetchCollections();
    }, [refresh]);

    return (
        <>
            <div>
                <Input onChange={(e) => doneTyping(e.target.value)} placeholder='Search Face Collection' />
            </div>
            <div style={{ marginTop : 24, }}>
                <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                    <Table
                    scroll={{ x : 400, }}
                    dataSource={collections}
                    className='hub-table bordered'
                    columns={[{
                        title : 'No',
                        dataIndex : 'collection',
                        key : 'collection',
                        width : 100,
                        render : (text, row, index) => {
                            const no = (index + 1);
                            return (
                                <>
                                    <div>
                                        <span>{no}</span>
                                    </div>
                                </>
                            )
                        }
                    },{
                        title : 'Face Collection',
                        dataIndex : 'collection',
                        key : 'collection',
                    },{
                        title : 'Action',
                        dataIndex : 'action',
                        key : 'action',
                        width : 100,
                        render : (text, row, index) => {
                            const { collection } = row;

                            return (
                                <>
                                    <div>
                                        <Space>
                                            <div>
                                                <Link to={`/dev/face_collection/${collection}`}>
                                                    <Button>
                                                        View
                                                    </Button>
                                                </Link>
                                            </div>

                                            <div>
                                                <Popconfirm
                                                onConfirm={() => deleteCollection(collection)}
                                                title={collection}
                                                description={`This action is not reversible, are you sure to delete this collection?`}
                                                >
                                                    <Button>
                                                        Delete
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        </Space>
                                        
                                    </div>
                                </>
                            )
                            
                        }
                    }]}
                    />
                </Spin>
            </div>
        </>
    );
}

export default CollectionList;